<template>
  <v-avatar :class="`avatar avatar-${avatarType} border-${borderType}`">
    <img
      height="46"
      width="46"
      :src="url"
      alt="Avatar"
    >
  </v-avatar>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    url: {
      type: String,
      default: 'https://image.flaticon.com/icons/png/512/847/847969.png',
    },
    avatarType: {
      type: String,
      default: '',
    },
    borderType: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
  .avatar {
    border-radius: 50%;
    opacity: 1;
    object-fit: cover;

    &.avatar-good {
      box-shadow: 0px 3px 6px $shadow-green;
    }

    &.avatar-improve {
      box-shadow: 0px 3px 6px $shadow-orange;
    }

    &.avatar-toolbar-border {
      border: 2px solid $post-good;
    }

    &.border-good {
      border: 2px solid $post-good;
    }

    &.border-improve {
      border: 2px solid $post-improve;
    }

    &.border-todo {
      border: 2px solid $post-todo;
    }
  }

</style>
